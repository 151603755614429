<template>
  <div class="app-contianer orderAccount f36">
    <ul class="orderList-box">
      <li class="flex" v-for="item in billList" :key="item.logId">
        <div class="select flex-center margin-l2" :class="payBillList.indexOf(item.orderId) >= 0 ? 'active' : ''" @click="selectBill(item)">
          <van-icon name="success" />
        </div>
        <div class="page-item flex-1">
          <div class="channel">
            {{ item.rangeTime }}
          </div>
          <div class="f38 margin-tb2 font-bold">{{ item.shopName }}</div>
          <p class="grey f34"
            >对账单号: {{ item.orderId }}
            <span class="f30 mainColor margin-lr2" v-clipboard:copy="item.orderId" v-clipboard:success="oncopy"> 复制 </span>
          </p>
          <p class="grey f34">支付金额: {{ item.realAmount }} 元</p>
          <p class="grey f34">订单数量: 共{{ item.orderNum }} 单</p>
          <p class="grey f34 flex flex-ai-c">
            <span class="margin-r1">订单状态: </span><van-tag type="warning" size="mini" plain>待支付</van-tag>
          </p>
          <div class="flex flex-jc-end flex-ai-c" @click="goDetal(item)">
            <div>查看详情</div>
            <van-icon name="arrow" />
          </div>
        </div>
      </li>
    </ul>
    <div v-if="billList.length === 0 && loading === false" class="grey tac">暂未待支付对账单~</div>

    <div class="pay-box flex">
      <div class="selectAll flex-center margin-l2" :class="payBillList.length === billList.length ? 'active' : ''" @click="selectAll()">
        <van-icon name="success" />
        <span class="margin-l2"> 全选 {{ payBillList.length }} / {{ billList.length }} </span>
      </div>
      <div class="submitBut flex-1" @click="handlePay()"> 立即支付 </div>
    </div>

    <van-popup v-model="open" position="bottom" round>
      <div class="popup-content">
        <div class="popup-title f40"><strong>支付确认</strong></div>
        <div class="row f40"> <span class="grey">对账单数量: </span>共 {{ billStat.billNum }} 单</div>
        <div class="row f40"> <span class="grey">总订单数量: </span>共 {{ billStat.orderNum }} 单</div>
        <div class="row f40"> <span class="grey">付 款 金 额: </span>￥{{ billStat.totalAmount }} </div>
        <div class="row f40">
          <span class="grey"> 备 注 : </span>
          <input type="text" v-model="remark" placeholder="请输入备注" />
        </div>
      </div>
      <div class="submitBut" @click="payAccount()"> 立即支付 </div>
    </van-popup>
  </div>
</template>

<script>
import { mallApi } from '@/api/index'
import { mapState } from 'vuex'
import { Dialog, Toast, ImagePreview } from 'vant'
import { dateFormat } from '@/utils/utils'
import wx from 'weixin-js-sdk'
import thechangePhone from '@/components/changePhone.js'

export default {
  name: 'BillAccount',
  data() {
    return {
      customerId: '',
      shopIdList: [],
      billList: [],
      payBillList: [],
      billStat: {},
      loading: true,

      open: false,
      remark: ''
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'agentConfig'])
  },
  filters: {
    timeFilter(val) {
      return dateFormat(new Date(val))
    },
    emptyFileter(val) {
      return typeof val === 'undefined' ? '--' : val
    }
  },
  created() {
    // 未绑定手机号
    if (this.custMsg.phoneUser === 'N') {
      thechangePhone()
    }

    if (this.$route.query.customerId) this.customerId = this.$route.query.customerId
    if (this.$route.query.shopId) this.shopIdList = [this.$route.query.shopId]
    this.getOrderInfo()
  },
  methods: {
    getOrderInfo() {
      let params = {
        trxCode: 'XF919',
        shopType: '030',
        status: 'REPAY'
      }
      if (this.customerId) params.customerId = this.customerId
      if (this.shopIdList.length > 0) params.shopIdList = this.shopIdList
      this.loading = true
      mallApi(params).then((res) => {
        this.loading = false
        let { data, rspCode } = res
        if (rspCode === '0000') {
          this.billList = data.billInfoList
        }
      })
    },

    handlePay() {
      this.open = true
      this.billStat = { billNum: 0, totalAmount: 0, orderNum: 0 }
      this.payBillList.forEach((item) => {
        let bill = this.billList.find((v) => v.orderId === item)
        if (bill) {
          this.billStat.billNum += 1
          this.billStat.totalAmount += bill.realAmount
          this.billStat.orderNum += bill.orderNum
        }
      })
    },

    // 支付
    payAccount() {
      if (this.agentConfig.PARTNER_FLAG != 'Y') {
        this.$toast('平台未配置服务商, 请联系管理员')
        return
      }
      if (this.billList.length === 0) {
        this.$toast('暂未待支付对账单')
        return
      }

      if (this.loading) return

      // 未绑定手机号
      if (this.custMsg.phoneUser === 'N') {
        thechangePhone()
        return
      }

      let params = {
        trxCode: 'XF183',
        requestType: 'MP',
        payTrxCode: 'TC003',
        orderIdList: this.payBillList,
        remark: `付款人:${this.custMsg.custId}。${this.remark}`
      }
      this.loading = true
      let toast = Toast.loading({
        duration: 0, // 持续展示
        forbidClick: true,
        message: '支付中...'
      })
      mallApi(params).then((res) => {
        this.loading = false
        this.open = false
        toast.clear()
        if (res.rspCode === 'P000') {
          var codeUrl = res.payInfo
          wx.chooseWXPay({
            timestamp: codeUrl.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: codeUrl.nonceStr, // 支付签名随机串，不长于 32 位
            package: codeUrl.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: codeUrl.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: codeUrl.paySign, // 支付签名
            success: function (res) {
              this.$toast.success('支付成功')
            },
            fail(err) {
              this.$toast.fail(err.errMsg)
            }
          })
        } else if (res.rspCode === '0006') {
          this.$toast(res.rspMsg)
          this.$router.push({ path: '/signin' })
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },

    selectBill(row) {
      let index = this.payBillList.indexOf(row.orderId)
      if (index >= 0) {
        this.payBillList.splice(index, 1)
      } else {
        this.payBillList.push(row.orderId)
      }
    },

    selectAll() {
      if (this.payBillList.length === this.billList.length) {
        this.payBillList = []
      } else {
        this.payBillList = this.billList.reduce((pre, item) => {
          pre.push(item.orderId)
          return pre
        }, [])
      }
    },

    goDetal(row) {
      this.$router.push({ path: '/billAccount', query: { billId: row.orderId } })
    },

    oncopy() {
      Toast('复制成功')
    }
  }
}
</script>

<style lang="less" scoped>
p {
  margin-top: 1vw;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-top {
  background: #fff;
  padding: 4vw;
}

.shopInfo-box {
  display: flex;
  align-items: center;

  > img {
    width: 20vw;
    height: 20vw;
    margin-right: 2vw;
  }
}

.account-box {
  margin-top: 4vw;
  margin-left: 3vw;
  margin-right: 3vw;
}

.orderList-box {
  margin-bottom: 100px;

  .channel {
    display: flex;
    align-items: center;
    padding-bottom: 2vw;
    border-bottom: 1px solid #eee;

    .icon {
      width: 5vw;
      margin-right: 2vw;
    }
  }

  .picture {
    width: 20vw;
    height: 20vw;
    overflow: hidden;
    display: inline-block;
    margin-right: 4vw;
  }

  .commitPics {
    margin-top: 4vw;
  }

  .van-icon-success {
    padding: 1px;
    color: #f7f6f9;
    border: 1px solid #999;
    border-radius: 50%;
  }

  .select.active {
    .van-icon-success {
      background: #26273b;
      border-color: #26273b;
    }
  }
}

.pay-box {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #fff;

  .selectAll {
    width: 120px;
  }

  .van-icon-success {
    padding: 1px;
    color: #f7f6f9;
    border: 1px solid #999;
    border-radius: 50%;
  }

  .selectAll.active {
    .van-icon-success {
      background: #26273b;
      border-color: #26273b;
    }
  }

  .submitBut {
    margin: 4vw;
  }
}

.popup-content {
  padding: 4vw;

  .popup-title {
    margin-bottom: 6vw;
  }

  .row {
    margin-top: 4vw;
  }
}
</style>
